.bucket{
	img{
		width:100%;
		max-width:190px;
		&.bucket__image--sm{
			max-width:130px;
		}
		+ .bucket__content{
			margin-top:30px;
		}
	}
	&__content{
		margin-bottom:30px;
		ul{
			list-style:none;
			padding:0;
		}
	}
	&__subtitle{
		text-transform:uppercase;
		font-weight:600;
		display:inline-block;
		margin-bottom:5px;
		+ h3{
			margin-top:0;
		}
	}
}

@media(max-width:767px){
	.bucket{
		img{
			width:100%;
			max-width:200px;
		}
	}
}