.tooltip{
	border:1px solid darken($color-neutral-lt, 10%);
	width:250px;
	color:$color-neutral-dk;
	&--top{
		&:before{
			border-top-color:darken($color-neutral-lt, 10%);
		}
	}
}

@media(max-width:767px){
	.tooltip{
		position:static;
		top:auto;
		transform:translate(0, 0);
		width:100%;
		margin-bottom:0;
		margin-top:10px;
		&:before,
		&:after{
			display:none;			
		}
	}
}