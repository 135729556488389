html{
	width:100%;
}

body{
	background:transparent;
	overflow:visible;
	width:100%;
	max-width:100%;
}

#wrapper{
	opacity:1;
	transition:transform 0.8s ease-in-out, opacity 1s ease-in-out;
}

h1, .h1{
  @include set-type(46, 600, 54);
}

h2, .h2{
  @include set-type(32, 600, 34);
}

h3, .h3{
  @include set-type(24, 600, 28);
}

h4, .h4{
  @include set-type(20, 600, 24);
}

h5, .h5{
  @include set-type(18, 600, 22);
}

h6, .h6{
  @include set-type(16, 600, 20);
}

#wpadminbar{
	a{
		@include remove-link-interaction();
	}
}

a{
	color:$color-purple-dk;
	font-weight:600;
	@include link-interaction($color-purple-dk, 'true');
}

strong{
	font-weight:600;
}

p{
	margin-bottom:20px;
	@include set-type(16, 400, 30);
}

ul,
ol{
	li{
		@include set-type(16, 400, 30);
	}
}

hr{
	background:$color-neutral;
}

img{
	max-width: 100%;
	
	&.alignright{
		float:right;
	}
	&.alignleft{
		float:left;
	}

	&.icon {
		max-width: 150px;
	}
}

.alignright{
	float:right;
}

.alignleft{
	float:left;
}

.text--centered {
	img {
		margin-left:auto;
		margin-right:auto;
	}
}

blockquote{
	margin:45px 0;
	p{
		@include set-type(24, 400, 32);
		font-style:italic;
		strong{
			font-style:normal;
			font-size:0.8em;
		}
	}
}

ol{
	list-style:none;
	counter-reset: custom-counter;
	padding-left:0;
	li{
		counter-increment: custom-counter;
		padding-left:25px;
		position:relative;
		&:before{
			content:counter(custom-counter) ". ";
			color:$color-dk;
			font-weight:600;
			position:absolute;
			left:0;
			top:0;
		}
	}
}

.grid{
	*[class*="grid__col"]{
		transition:width 0.2s ease-in-out;
	}
	&--padded{
		*[class*="grid__col"]{
			padding:0 30px;
		}
	}
	&--no-offset{
		margin:0;
		*[class*="grid__col"]{
			padding:0;
		}
	}
	&--data{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		*[class*="grid__col"]{
			width:33.3333%;
			margin-bottom:0;
		}
	}
	&__col{
		&--padded-right{
			padding-right:10%;
			.section__content--centered{
				padding-right:10%;
			}
		}
		&--padded-left{
			padding-left:10%;
			.section__content--centered{
				padding-left:10%;
			}
		}
	}
	&__image{
		max-width:100%;
		width:100%;
	}
	&--divided{
		+ hr{
			margin:45px 0;
		}
		>	*[class*="grid__col"]{
			border-right:1px solid $color-neutral;
			padding:0 45px;
			&:first-of-type{
				padding-left:15px;
			}
			&:last-of-type{
				padding-right:15px;
				border-right:none;
			}
		}
	}
}

.customers{
	padding:70px 0;
	
	p {
		margin-bottom: 50px;
		color:#212831;
	}

	img{
		margin:5px 10px;
	    height: 50px;
	    display: inline-block;
	}
}

.data{
	&__value{
		display:block;
		@include set-type(48, 600, 54);
		margin-bottom:20px;
	}
	&__description{
		@include set-type(22, 400, 28);
	}
}

.divider__image{
	width:100%;
	height:auto;
}

.integration-bucket{
	text-align:center;
	&__image{
		width:100%;
		max-width:150px;
		margin:15px 0;
	}
}

.featured-blog__wrapper{
	padding-right:60px;
}

.feature{
	&__image{
		position:absolute;
		display:none;
		height:auto;
		width:250px;
		&:nth-of-type(1){
			top:15%;
			left:13%;
			display:block;
		}
		&:nth-of-type(2){
			top:15%;
			right:13%;
			display:block;
		}
		&:nth-of-type(3){
			bottom:15%;
			left:13%;
			display:block;
		}
		&:nth-of-type(4){
			bottom:15%;
			right:13%;
			display:block;
		}
	}
	img{
		margin-bottom:15px;
		max-width:100%;
	}
}

.featured-image{
	margin-bottom:20px;
	display:block!important;
	margin-left:auto;
	margin-right:auto;
}

.wp-block-embed__wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.related{
	&__header{
		padding-top:45px;
	}
	&__link{
		@include set-type(24, 400, 28);
	}
}

.sharing{
	transition:padding 0.2s ease-in-out;
	&__list{
		list-style:none;
		padding:0;
		li{
			a{
				display:block;
				text-align:center;
				height:45px;
				width:45px;
				padding:13px 0;
				border-radius:50px;
				background:$color-teal-lt;
				@include set-type(20, 400, 20);
				@include remove-link-interaction();
				color:$color-neutral-dk;
				transition:background 0.2s ease-in-out, color 0.2s ease-in-out;
				&:hover,
				&:focus{
					background:$color-teal;
					color:$color-lt;
				}
			}
		}
	}
}

.scrolled{
	.sharing{
		padding-top:15px;
	}
}

.jumplinks{
	&--grid{
		.jumplink{
			max-width:285px;
			margin:0 15px;
			@include remove-link-interaction();
			position:relative;
			border:15px solid $color-teal-lt;
			color:$color-neutral-dk;
			text-align:center;
			display:block;
			transition:border 0.2s ease-in-out;
			&:hover,
			&:focus{
				border:15px solid $color-teal;
				border-bottom:15px solid $color-teal;
			}
			&__descr{
				display:block;
				@include set-type(18, 400, 24);
			}
			&__title{
				display:block;
				@include set-type(18, 600, 22);
				margin-bottom:20px;
				margin-top:10px;
			}
			&__content{
				position:relative;
				z-index:2;
				padding:30px 10px 10px;
			}
			img{
				width:50%;
			}
			&:before{
				content:'';
				position:absolute;
				top:0;
				left:0;
				height:100%;
				width:100%;
				background:$color-lt;
				box-shadow:0 0 10px $color-neutral;
				z-index:1;
			}
		}
	}
}

.post-card{
	position:relative;
	border:15px solid $color-teal-lt;
	color:$color-neutral-dk;
	text-align:center;
	display:block;
	transition:border 0.2s ease-in-out;
	width:100%;
	&__descr{
		display:block;
		@include set-type(18, 400, 24);
		margin-bottom:15px;
		&:last-of-type{
			margin-bottom:0;
		}
	}
	&__title{
		display:block;
		@include set-type(32, 600, 42);
		margin-bottom:20px;
	}
	&__content{
		position:relative;
		z-index:2;
		padding:30px 10px;
	}
	&__icon{
		max-width:100%;
		display:block;
		margin:20px auto;
	}
	&:before{
		content:'';
		position:absolute;
		top:0;
		left:0;
		height:100%;
		width:100%;
		background:$color-lt;
		box-shadow:0 0 10px $color-neutral;
		z-index:1;
	}
}

.page{
	&__subtitle{
		display:block;
		margin-bottom:15px;
		font-weight:400;
		font-size:0.6em;
		line-height:1.2em;
		+ .page__title{
			margin-top:0;
		}
	}
	&__title{
		@include set-type(42, 600, 58);
	}
}

.page-intro{
	&__content-wrapper{
		position:relative;
	}
	&__content{
		position:absolute;
		top:50%;
		left:0;
		transform:translateY(-50%);
		padding:0 20%;
	}
}

.features{
	justify-content:center;
	margin:0 -30px;
	margin-bottom:45px;
	*[class*="grid__col"]{
		padding:0 30px;
	}
}

.integrations{
	margin:0 -10px;
	margin-bottom:60px;
	flex-wrap:wrap;
	.card{
		&--dk{
			h2{
				@include set-type(32, 600, 42);
			}
			p{
				@include set-type(16, 400, 30);
			}
		}
		&__content{
			padding:30px;
		}
	}
	.integration{
		@include remove-link-interaction();
		padding:20px 30px;
		width:100%;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		box-shadow:0 0 5px $color-neutral;
		justify-content:center;
		text-align:center;
		&__category{
			display:block;
			width:100%;
		}
		&__wrapper{
			padding:10px;
			display:flex;
			align-items:stretch;
			justify-content:stretch;
		}
		img{
			width:100%;
			max-width:150px;
		}
		a{
			font-weight:600;
		}
	}
}

.outro{
	&__image{
		img{
			max-width:100%;
		}
	}
	&__content{
		padding:30px 90px;
	}
}

.video{
	&__wrapper{
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 25px;
		height: 0;
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.image{
	&__grid{
		@include clearfix();
		a{
			&:hover,
			&:focus{
				.image__block{
					box-shadow:0 0 10px $color-neutral;
				}
			}
		}
	}
	&__block{
		float:left;
		background-repeat:no-repeat;
		transition:box-shadow 0.2s ease-in-out;
		margin:0 1.5%;
		margin-bottom:20px;
		&--yellow{
			background-color:$color-yellow;
		}
		&--salmon{
			background-color:$color-salmon-lt;
		}
		&--purple{
			background-color:$color-purple-lt;
		}
		&--pink{
			background-color:$color-peach;
		}
		&--teal{
			background-color:$color-blue-lt;
		}
		&--green{
			background-color:$color-teal-lt;
		}
		&--orange{
			background-color:$color-orange-lt;
		}
		&--white{
			background-color:$color-lt;
		}
		&--small,
		&--medium{
			width:30.333%;
		}
		&--small{
			height:150px;
			width:30.333%;
			background-position:center;
			background-size:40%;
		}
		&--medium{
			height:320px;
			background-size:cover;
			background-position:bottom center;
		}
		&--large{
			height:320px;
			width:97%;
			background-size:cover;
		}
	}
}

.author{
	&__image{
		border-radius:100px;
	}
}

.team{
	margin-top:60px;
	justify-content:center;
	&__member{
		@include remove-link-interaction();
		color:$color-neutral-dk;
		font-weight:400;
		img{
			max-width:100%;
			margin-bottom:15px;
		}
		h3{
			@include set-type(20, 600, 24);
			margin-bottom:0;
		}
	}
}

.impact{
	border:15px solid $color-teal-lt;
	padding:30px;
	margin:60px 0;
	&__data{
		@include set-type(28, 400, 36);
	}
	&__data-wrapper{
		position:relative;
		i{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%, -50%);
			font-size:50px;
		}
		*[class*="grid__col"]{
			&:first-of-type{
				padding-right:70px;
			}
			&:last-of-type{
				padding-left:70px;
			}
		}
	}
}

.testimonial{
	&__quote{
		margin-top:60px;
		.card__content{
			padding-top:45px;
			padding-bottom:45px;
		}
	}
	&__quotation{
		width:45px;
		margin:20px auto;
	}
	&__quote-author{
		color:$color-teal-lt;
	}
	&__features-image{
		max-width:100%;
	}
	&__integrations-image{
		width:70px;
	}
}

.split-content{
	img{
		max-width:100%;
		height:auto;
	}
}

.small-order-1 {
	order:1;
}

.small-order-2 {
	order:2;
}

@media (min-width:991px) {
	.medium-order-1 {
		order:1;
	}

	.medium-order-2 {
		order:2;
	}
}



@media(max-width:1079px){
	.grid{
		&--data{
			*[class*="grid__col"]{
				width:50%;
				padding-top:30px;
				padding-bottom:30px;
			}
		}
	}
}

@media(max-width:991px){
	.featured-blog{
		&__wrapper{
			padding-right:15px;
		}
	}

	.jumplinks{
		&--grid{
			.jumplink{
				margin:15px auto;
			}
		}
	}
}

@media(max-width:767px){
	body{
		overflow-x:hidden;
	}
	.grid{
		&--padded{
			*[class*="grid__col"]{
				padding:0 15px;
			}
		}
		&--data{
			*[class*="grid__col"]{
				margin-bottom:60px;
				width:100%;
			}
			&:last-of-type{
				*[class*="grid__col"]{
					&:last-of-type{
						margin-bottom:0;
					}
				}
			}
		}
		&__col{
			&--padded-left{
				padding-left:15px;
				.section__content--centered{
					padding-left:0;
				}
			}
			&--padded-right{
				padding-right:15px;
				.section__content--centered{
					padding-left:0;
				}
			}
		}
		&--divided{
			+ hr{
				margin:45px 0;
			}
			>	*[class*="grid__col"]{
				border-right:none;
				padding:0 15px;
				&:first-of-type{
					padding-left:15px;
				}
				&:last-of-type{
					padding-right:15px;
				}
			}
		}
	}
	.integration-buckets{
		*[class*="grid__col"]{
			margin-bottom:0;
		}
	}
	.sharing{
		&__list{
			margin-bottom:30px;
			li{
				display:inline-block;
			}
		}
	}
	.scrolled{
		.sharing{
			padding-top:0;
		}
	}
	.jumplinks{
		&--grid{
			margin:0;
		}
	}
	.integrations{
		margin-bottom:30px;
		.integration{
			&__wrapper{
				margin-bottom:0;
			}
		}
	}
	.outro{
		&__content{
			padding:0 15px;
		}
	}
	.page-intro{
		&__content{
			position:static;
			transform:translateY(0);
			padding:0;
		}
	}	
	.impact{
		&__data-wrapper{
			i{
				transform:rotate(90deg) translate(-50%, 50%);
			}
			*[class*="grid__col"]{
				&:first-of-type{
					padding-right:15px;
					padding-bottom:70px;
				}
				&:last-of-type{
					padding-top:70px;
					padding-left:15px;
				}
			}
		}
	}

}


@media screen and (prefers-reduced-motion: reduce) {
	#wrapper{
		transition:none!important;
	}
	.transition-box{
		display:none!important;
		&__left,
		&__right{
			transition:none!important;
		}
	}
}

@media screen and (prefers-reduced-motion) {
	#wrapper,
	.header__navbar,
	.header__brand,
	.notification{
		transition:none!important;
	}
	.header__brand{
		img{
			transition:none!important;
		}
	}
	.transition-box{
		display:none!important;
		&__left,
		&__right{
			transition:none!important;
		}
	}
}