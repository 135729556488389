// FONTS
$font-body-family: 'Gibson',
sans-serif;
$font-display-family: 'Gibson',
sans-serif;
$color-lt:#fff;
$color-grey:#eee;
// COLORS
$color-teal: #3BBEAD;
$color-teal-lt: #C4E8D9;
$color-teal-dk: #0A9981;
$color-teal-med: #B8DFCF;
$color-bg-blue: #B3E0E2;
$color-blue: #65B2E0;
$color-blue-lt: #AEE8E7;
$color-blue-dk: darken($color-blue, 20%);
$color-orange: #FF9D2F;
$color-orange-lt: lighten($color-orange, 20%);
$color-orange-dk: darken($color-orange, 20%);
$color-yellow: #F4D74E;
$color-yellow-lt: #F8DD91;
$color-yellow-dk: #D6A75F;
$color-green: #CED26D;
$color-green-lt: #F8F4D8;
$color-green-dk: #B9AC41;
$color-purple: lighten(#484C83, 20%);
$color-purple-lt: #C5BAC0;
$color-purple-dk: #484C83;
$color-salmon: #FE8675;
$color-salmon-lt: #F3D8D2;
$color-salmon-dk: darken($color-salmon, 10%);
$color-peach: #F8E0C4;
$color-peach-lt: lighten($color-peach, 20%);
$color-peach-dk: darken($color-peach, 20%);
$color-bg: #FEF8EB;
$color-neutral: #C5C8D1;
$color-neutral-lt: #F3F7F8;
$color-neutral-dk: #232A31;
$color-neutral-dk-2: #797979;
$color-link-dk: #212831;
$color-brand-1: $color-salmon;
$color-brand-1-lt: $color-salmon-lt;
$color-brand-1-dk: $color-salmon-dk;
$color-brand-2: $color-neutral;
$color-brand-2-lt: $color-neutral-lt;
$color-brand-2-dk: $color-neutral-dk;