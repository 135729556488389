.posts{
	&--list{
		.post{
			padding:20px 0;
			border-top:1px solid $color-neutral;
			h3{
				margin:0;
				color:$color-neutral-dk;
				@include set-type(18, 600, 28);
				@include link-interaction($color-neutral-dk);
			}
			&:last-of-type{
				border-bottom:1px solid $color-neutral;
			}
		}
	}
	&--grid{
		.grid{
			display:flex;
			margin-left:-15px;
			margin-right:-15px;
			.post{
				padding:30px;
				margin-left:15px;
				margin-right:15px;
				background:$color-lt;
				&.post--sm {
					padding:15px 15px 30px;

					h3 {
						font-size:1.5rem;
					}

					.post__image {
						margin-bottom: 15px;
					}
				}
			}
		}
		.post{
			&__types{
				margin-bottom:-15px;
				margin-top:30px;
			}
			&__image{
				display:block;
				width:100%;
			}
			h2,
			h3{
				margin:0;
				color:$color-neutral-dk;
				@include set-type(26, 600, 36);
				@include link-interaction($color-neutral-dk);
			}
			&__meta{
				margin-bottom:15px;
			}
		}
	}
	&__footer{
		margin-top:45px;
	}
}

.blog-posts{
	background:$color-bg;
	padding:60px 0;
	&.section--page-content,
	&.prefooter{
		background:transparent;
	}
}

.post{
	display:block;
	color:$color-neutral-dk;
	@include remove-link-interaction();
	> a{
		@include remove-link-interaction();
	}
	&--featured{
		.post__meta{
			margin-bottom:20px;
		}
		.post__types{
			margin-bottom:0;
			margin-top:15px;
		}
		h2{
			color:$color-neutral-dk;
			@include set-type(36, 600, 42);
			@include link-interaction($color-neutral-dk);
		}
		img{
			width:100%;
		}
	}
	img{
		max-width:100%;
	}
	&__image{
		margin-bottom:30px;
	}
	&__title{
		margin:0;

		a {
			color:$color-neutral-dk;
			border-bottom-color: transparent;
		}
	}
	&__meta{
		+ h1{
			margin-top:20px;
		}
	}
	&__types{
		margin-bottom:30px;
	}
	&__type{
		@include remove-link-interaction();
		display:inline-block;
		margin-bottom:15px;
		margin-right:15px;
		padding:5px 10px;
		background:$color-teal-lt;
		color:$color-neutral-dk;
		font-weight:600;
		transition:background 0.2s ease-in-out;
		&:hover,
		&:focus{
			background:$color-teal;
		}
	}
	&__author{
		font-style:italic;
		padding-top:30px;
	}
	&__content{
		margin-bottom:45px;
		&:last-child{
			margin-bottom:45px;
		}
		h1{
			margin-bottom:45px;
		}
		img{
			max-width:100%;
			height:auto;
			display:inline-block;
		}
		blockquote{
			padding:45px 0;
			margin-bottom:20px;
			position:relative;
			&:before{
				content:'“';
				position:absolute;
				left:-75px;
				top:10px;
				@include set-type(200, 600, 200);
				color:$color-teal-lt;
				z-index:-1;
			}
			cite{
				@include set-type(20, 400, 30);
				color:#66696D;
			}
			p{
				@include set-type(24, 600, 34);
				font-style:italic;
				color:#66696D;
			}
		}
		.wp-block-pullquote{
			padding:15px 0;
			text-align:left;
			blockquote{
				padding:0;
				padding-left:30px;
				border-left:5px solid $color-brand-1;
				cite{
					@include set-type(14, 400, 20);
					color:#66696D;
				}
				p{
					@include set-type(16, 600, 22);
					font-style:italic;
					color:#66696D;
				}
				&:before{
					display:none;
				}
			}
		}
		hr{
			margin:45px 0;
		}
		.post__meta{
			@include set-type(18, 400, 24);
		}
	}
}

.wp-block-gallery{
	.blocks-gallery-item{
		figure{
			flex-direction:column!important;
		}
		figcaption{
			position:static!important;
			background:none!important;
			color:#555d66!important;
		}
	}
} 

@media(max-width:767px){
	.posts{
		&--list{
			.post{
				border-top:none;
				padding:15px 0;
				&:last-of-type{
					border-bottom:none;
				}
			}
		}
		&__footer{
			.button{
				width:100%;
			}
		}
	}
	.post{
		&__content{
			blockquote{
				margin:0;
				padding:30px 0;
				p{
					@include set-type(28, 600, 34);
				}
			}
		}
	}
}

@media(max-width:767px){
	.posts--grid{
		.grid{
			display:block;
			margin-left:0;
			margin-right:0;
			.post{
				margin-left:0;
				margin-right:0;
			}
		}
	}
	.popular-posts{
		background:$color-lt;
		padding:45px 15px;
		margin-left:-15px;
		margin-right:-15px;
		margin-bottom:-60px;
	}
}