.gridable--col{
  padding:0 ($gutter-width);
  margin-bottom:($gutter * 2) + px;
  width:100%;
  &:last-of-type{
    margin-bottom:0;
  }
}

.gridable--row{
  @include clearfix();
  margin:0 (-$gutter-width);
  margin-bottom:($gutter * 2) + px;
  &:last-of-type{
    margin-bottom:0;
  }
}

.grid{
  + h1{
    margin-top:0;
  }

  &--flex {
    flex-flow:row wrap;
  }

  &--flex-center{
    align-items:center;
  }

  &--align-center {
    justify-content:center;
  }

  &--align-justify {
    justify-content:space-between;
  }

  &--align-spaced {
    justify-content:space-around;
  }

  &--align-left {
    justify-content:flex-start;
  }
}

@media(min-width: 768px){
  @for $i from 1 through $columns{
    .gridable--col.col-#{$i}{
      width: (($i / $columns) * 100%);
      float:left;
      margin-bottom:0;
    }
  }
}
