/* FORM COMPONENTS
---------------------------------------------------------------------------*/
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
textarea,
select{
  width:100%;
  max-width:100%;
  appearance:none;
  border:none;
  border-radius:3px;
  background:$color-lt;
  color:$color-brand-2-dk;
  font-family:$font-body-family;
  padding:10px;
  margin:0;
  text-align:left;
  border:1px solid $color-neutral;
  @include set-type(16, 400, 20);
}

label{
  display:block;
  margin-bottom:5px;
  font-weight:400!important;
}

input[type="button"],
input[type="submit"]{
  @extend .button;
  @extend .button--secondary;
  display:block!important;
  margin:0 auto!important;
}

.nf-before-form-content{
  margin-bottom:30px;
}

.nf-response-msg{
  @extend .alert;
}

.nf-field-element{
  position:relative;
  select{
    padding-right:45px;
  }
}

.newsletter-signup{
  position:fixed;
  bottom:0;
  right:0;
  z-index:98;
  padding:30px;
  padding-top:105px;
  overflow-y:auto;
  max-height:100%;
  width:100%;
  max-width:450px;
  transform:translateY(100%);
  transition:transform 0.6s ease-in-out;
  &--active{
    transform:translateY(0);
  }
  form{
    padding:45px;
    background:$color-neutral-dk;
    color:$color-lt;
    box-shadow:0 0 15px $color-neutral;
    position:relative;
    .dismiss{
      position:absolute;
      top:0;
      right:0;
      padding:15px;
      background:transparent;
      margin:0;
    }
    h2{
      margin-top:0;
      color:$color-bg;
    }
    .dismiss{
      color:$color-bg;
    }
  }
  .email{
    margin-bottom:20px;
  }
}

.filters{
  margin-bottom:30px;
  &__toggle{
    appearance:none;
    padding:10px 45px;
    border:none;
    border-radius:0;
    width:100%;
    display:block;
    text-align:left;
    @include set-type(18, 600, 28);
    background:$color-teal-lt;
		color:$color-neutral-dk;
		font-weight:400;
    transition:all 0.2s ease-in-out;
    cursor:pointer;
    position:relative;
    .fa-tasks{
      position:absolute;
      left:15px;
      top:50%;
      transform:translateY(-50%);
    }
    .fa-angle-down{
      position:absolute;
      right:15px;
      top:50%;
      transform:translateY(-50%);
      transition:transform 0.2s ease-in-out;
    }
    &.open{
      .fa-angle-down{
        transform:translateY(-50%) rotate(-180deg);
      }
    }
    &:hover,
		&:focus{
			background:$color-teal;
		}
  }
  &__drawer{
    background:$color-lt;
    padding:30px;
    .button{
      margin:0;
      margin-left:15px;
    }
  }
}

.active-filters{
  .post__type{
    margin-left:15px;
  }
}

.form__group {
  &--inline-button {
    position: relative;
    width: 100%;
    max-width: 420px;
    margin-top: 35px;

    .form__input {
      width:66%;
      box-shadow: none;
      border-color: transparent;
      line-height: 1.4;
      height:50px;
    }

    .button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      height:50px;
    }
  }
}

.list--checkboxes{
  list-style:none;
  padding:0;
  display:flex;
  flex-wrap:wrap;
  .checkbox{
    width:25%;
    padding:0 25px;
    position:relative;
    label{
      cursor:pointer;
      i{
        opacity:0;
        position:absolute;
        left:1px;
        top:2px;
        transition:opacity 0.2s ease-in-out;
      }
      &:before{
        content:'';
        height:15px;
        width:15px;
        border:1px solid $color-neutral;
        position:absolute;
        left:0;
        top:2px;
        transition:all 0.2s ease-in-out;
      }
    }
    input{
      @include set-webaim-hidden();
      &:checked{
        + label{
          &:before{
            border:1px solid $color-salmon;
            background:$color-salmon;
          }
          i{
            opacity:1;
          }
        }
      }
    }
  }
}

@media(max-width:991px){
  .list--checkboxes{
    .checkbox{
      width:33.333%;
    }
  }
}

@media(max-width:767px){
  .newsletter-signup{
    padding-left:15px;
    padding-right:15px;
  }
  .list--checkboxes{
    .checkbox{
      width:50%;
    }
  }
}

@media(max-width:550px){
  .list--checkboxes{
    .checkbox{
      width:100%;
    }
  }
}