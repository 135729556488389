/* Load Fonts Here
------------------------------------------------------------------------------*/

// Light
@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-Light.eot);
  src: url(../fonts/Gibson/Gibson-Light.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-Light.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-Light.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-Light.svg#gibson_light) format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-LightIt.eot);
  src: url(../../fonts/Gibson/Gibson-LightIt.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-LightIt.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-LightIt.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-LightIt.svg#gibson_lightit) format('svg');
  font-weight: 300;
  font-style: italic;
}

//Regular
@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-Regular.eot);
  src: url(../../fonts/Gibson/Gibson-Regular.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-Regular.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-Regular.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-Regular.svg#gibson_regular) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-Italic.eot);
  src: url(../../fonts/Gibson/Gibson-Italic.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-Italic.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-Italic.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-Italic.svg#gibson_italic) format('svg');
  font-weight: 400;
  font-style: italic;
}

// SEMI-BOLD
@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-SemiBold.eot);
  src: url(../../fonts/Gibson/Gibson-SemiBold.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-SemiBold.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-SemiBold.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-SemiBold.svg#gibson_semibold) format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-SemiboldIt.eot);
  src: url(../../fonts/Gibson/Gibson-SemiboldIt.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-SemiboldIt.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-SemiboldIt.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-SemiboldIt.svg#gibson_semiboldit) format('svg');
  font-weight: 600;
  font-style: italic;
}

// BOLD
@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-Bold.eot);
  src: url(../../fonts/Gibson/Gibson-Bold.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-Bold.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-Bold.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-Bold.svg#gibson_bold) format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gibson';
  src: url(../../fonts/Gibson/Gibson-BoldIt.eot);
  src: url(../../fonts/Gibson/Gibson-BoldIt.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Gibson/Gibson-BoldIt.woff) format('woff'),
    url(../../fonts/Gibson/Gibson-BoldIt.ttf) format('truetype'),
    url(../../fonts/Gibson/Gibson-BoldIt.svg#gibson_boldit) format('svg');
  font-weight: 700;
  font-style: italic;
}

