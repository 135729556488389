.nav{
	&--primary{
		text-align:right;
		height:42px;
		.nav__menu{
			text-align:right;
			display:inline-block;
			margin:0;
			position:relative;
			height:42px;
			@include clearfix();
			li{
				margin-right:50px;
				padding-top:9px;
				padding-bottom:6px;
				display:inline-block;
				transform:opacity 0.2s ease-in-out;
				&[aria-hidden="true"]{
					opacity:0;
				}
				a,
				button{
					appearance:none;
					background:transparent;
					white-space:nowrap;
					color:$color-neutral-dk;
					border:none;
					@include set-type(16, 400, 24);
					@include link-interaction($color-neutral-dk);
					display:inline-block;
					padding:0;
					padding-bottom:3px;
					margin:0;
					font-weight:400;
					cursor:pointer;
					&.button{
						@include remove-link-interaction();
						display:inline-block;
					}

					&.dropdown-toggle:hover, &.dropdown-toggle:focus {
						
					}

					&.dropdown-toggle.active {
						i {
							transition:200ms ease;
						}
						i {
							transform:rotate(-180deg);
						}
					}
				}
				&.active{
					a{
						font-weight:600;
						border-bottom:1px solid $color-neutral-dk;
					}
				}
				&.mobile-toggle__wrapper{
					display:none;
					margin:0;
					.button{
						position:absolute;
						right:0;
						top:50%;
						transform:translateY(-50%);
					}
				}

				.dropdown-menu {
					display: none;
					position: absolute;
				    top: 60px;
				    left: -35%;
				    margin: 0;
				    text-align: left;
				    box-shadow: 0 0 5px rgba(0,0,0,0.1);
				    background: $color-lt;
				    padding: 10px 30px;
				    transform: translateX(-35%);

				    &:before {
				    	display: block;
				    	content: "▲";
				    	width: 0; 
						height: 0; 
				    	text-shadow: 0 -4px 5px rgba(0,0,0,0.1);
				    	font-size: 1.6em;
				    	position: absolute;
				    	top:-26px;
				    	left:50%;
				    	transform:translateX(50%);
				    	color:$color-lt;
				    }

				    li {
				    	margin:0;
				    	padding:0;
				    	display: block;

				    	a {
				    		display: block;
				    		padding:15px 5px;
				    		border-top:1px solid $color-grey;
				    		border-bottom: 0 none;
				    		transition:300ms ease;

					    	i {
				    			display: inline-block;
				    			color:$color-salmon;
				    			margin-right: 10px;
				    		}

				    		&:hover, &:focus, &:active {
				    			border-bottom: 0 none;
				    			color:$color-salmon;
				    		}
				    	}

				    	&:first-child {
				    		a {
				    			border:0 none;
				    		}
				    	}
				    }

				}


				&.resources-menu {
					.dropdown-menu {
						left: -60%;
					    width: 800px;
					    transform: translateX(-60%);
					    padding-top:35px;
					    padding-bottom: 20px;

					    &:before {
					    	left:auto;
					    	right:30%;
					    	transform:none;
					    }

					    .grid {
					    	margin:0;
					    }

					    ul {
					    	margin:0 25px 0 0;
					    	padding:0;
					    	width:40%;
					    }

					    .feature_downloads {
					    	width:55%;

					    	img {
					    		height:auto;
					    	}
					    	.grid {
					    		margin:0 -15px 20px;
					    		a {
					    			width:25%;
					    			margin:0 15px;
					    			transition:none;

					    			&:hover, &:active, &:focus {
					    				border:0 none;
					    			}
					    		}
					    	}
					    }
					}
				}
			}
		}
		.button--primary{
			margin:0;
			position:absolute;
			right:15px;
			top:50%;
			transform:translateY(-50%);
		}
	}
	&--mobile{
		.nav__menu{
			li{
				padding:10px 0;
				a, button{
					appearance:none;
					cursor:pointer;
					background:transparent;
					border:none;
					@include set-type(20, 600, 26);
					color:$color-neutral-dk;
					@include link-interaction($color-neutral-dk, 'false');
				}

				.dropdown-menu {
					display: none;

				    li {
				    	list-style: none;

				    	a {
					    	i {
				    			display: inline-block;
				    			color:$color-salmon;
				    			margin-right: 10px;
				    		}
				    	}

				    	&:first-child {
				    		a {
				    			border:0 none;
				    		}
				    	}
				    }

				}
			}
		}
	}
	&--footer,
	&--social{
		.nav__parent-item{
			display:block;
			margin-bottom:20px;
			@include set-type(18, 600, 24);
		}
	}
	&--footer{
		.nav__menu{
			.nav__menu{
				li{
					margin-bottom:20px;
					a{
						color:$color-purple-dk;
						font-weight:400;
						@include link-interaction($color-purple-dk, 'true')
					}
				}
			}
		}
	}
	&--social{
		.nav__menu{
			.nav__menu{
				li{
					display:inline-block;
					margin-right:20px;
					&:last-of-type{
						margin-right:0;
					}
					a{
						color:$color-purple-dk;
						@include link-interaction($color-purple-dk, 'false');
					}
				}
			}
		}
	}
}

.mobile-nav{
	width:100%;
	padding:15px;
	background:$color-neutral-lt;
	&__wrapper{
		display:none;
		position:fixed;
		z-index:99;
		top:0;
		padding-top:102px;
		right:0;
		width:100%;
		max-height:100%;
		overflow-y:auto;
		transition:padding-top 0.2s ease-in-out;
		.container{
			@include clearfix();
		}
	}
	.button{
		margin:0;
		text-align:center;
	}
}

@media(max-width:768px){
	.nav{
		&--primary{
			.nav__menu{
				width:0;
				li{
					&[data-slug]{
						opacity:0;
					}
				}
			}
		}
	}
	.mobile-nav{
		float:none;
		width:100%;
	}

	.nav__menu li.resources-menu .dropdown-menu .feature_downloads {
		display: none;
	}
}


.wicketwise_sidenav{
	ul{
		padding: 0;
	}
	li{
		font-size: 14px;
		padding: 4px 10px;
		list-style: none;
		&.current-menu-item{
			background-color: $color-teal-lt;
		}
	}
}