.list{
	&--pills{
		list-style:none;
		padding:0;
		li{
			margin:0 10px;
			display:inline-block;
			a{
				display:inline-block;
				padding:8px;
				box-shadow:0 0 10px $color-neutral;
				border-radius:2px;
				background:$color-lt;
				@include remove-link-interaction();
				transition:box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
				&:hover,
				&:focus{
					box-shadow:0 0 30px $color-neutral;
				}
				&:active{
					transform:translate(1px, 1px);
				}
				img{
					height:35px;
					width:auto;
				}
			}
		}
	}
	&--icons{
		list-style:none;
		padding:0;
		li{
			position:relative;
			padding-left:30px;
			i{
				position:absolute;
				left:0;
				top:3px;
			}
			img{
				width:100%;
				max-width:150px;
			}
		}
	}
	&--integration-logos{
		li{
			@include clearfix();
			i{
				top:50%;
				transform:translateY(-50%);
			}
			img{
				width:50%;
			}
		}
	}
	&--filters{
		list-style:none;
		padding:0;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		li{
			margin:15px 10px;
			input{
				@include set-webaim-hidden();
				+ label{
					display:block;
					padding:10px 15px;
					background:$color-teal-lt;
					border-radius:50px;
					cursor:pointer;
					font-weight:600;
					transition:background 0.2s ease-in-out, color 0.2s ease-in-out;
					&:hover,
					&:focus{
						background:$color-teal;
					}
				}
				&:checked{
					+ label{
						background:$color-teal;
						color:$color-lt;
					}
				}
			}
		}
	}
}

.check-list{
	list-style:none;
	padding:0;
	li{
		position:relative;
		padding:10px 0;
		padding-left:45px;
		i{
			position:absolute;
			left:0;
			top:6px;
			background:#fe8675;
			border-radius:50px;
			height:30px;
			width:30px;
			padding:7px;
		}
	}
}

@media(max-width:767px){
	.list{
		&--filters{
			display:block;
			li{
				text-align:center;
				display:block;
				input + label{
					display:inline-block;
				}
			}
		}
	}
}