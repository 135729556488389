.header{
	padding:0;
	background:transparent;
	&__navbar{
		position:fixed;
		padding:30px 0;
		top:0;
		left:0;
		width:100%;
		max-width:100%;
		z-index:1000;
		transition:padding 0.2s ease-in-out, background 0.2s ease-in-out, top 0.2s ease-in-out;
		.container{
			padding-left:215px;
			padding-right:190px;
			position:relative;
		}
		&--mobile-shown{
			.container{
				padding-right:200px;
			}
		}
	}
	&__brand{
		position:absolute;
		left:15px;
		top:50%;
		transform:translateY(-50%);
		margin:0;
		img{
			transition:width 0.2s ease-in-out;
			width:200px;
		}
	}
}

.scrolled{
	.header{
		&__navbar{
			padding:20px 0;
			background:$color-lt;
		}
		&__brand{
			img{
				width:175px;
			}
		}
	}
	.mobile-nav{
		&__wrapper{
			padding-top:72px
		}
	}
}

.admin-bar{
	.header{
		&__navbar{
			top:32px;
		}
	}
	.mobile-nav{
		&__wrapper{
			padding-top:134px;
		}
	}
	&.scrolled{
		.mobile-nav{
			&__wrapper{
				padding-top:102px;
			}
		}
	}
}

@media(max-width:782px){
	.admin-bar{
		.header{
			&__navbar{
				top:46px;
			}
		}
		.mobile-nav{
			&__wrapper{
				padding-top:148px;
			}
		}
		&.scrolled{
			.mobile-nav{
				&__wrapper{
					padding-top:118px;
				}
			}
		}
	}	
}

@media(max-width:520px){
	.header{
		&__navbar{
			.container{
				padding-right:15px;
			}
		}
	}
}

@media(max-width:600px){
	.admin-bar{
		&.scrolled{
			.header{
				&__navbar{
					top:0;
				}
			}
			.mobile-nav{
				&__wrapper{
					padding-top:72px;
				}
			}
		}
	}
}