.mobile,
.mobile--sm{
	display:none;
}

.sticky{
	position: -webkit-sticky;
  position: sticky;
  top: 72px;
}

.admin-bar{
	.sticky{
		top:102px;
	}
}

.divider{
	&--invisible{
		background:transparent;
		+ .grid{
			margin-top:30px!important;
		}
	}
}

.text{
	&--divider{
		display:inline-block;
		margin:0 10px;
		color:$color-neutral;
	}
	&--large{
		@include set-type(20, 400, 32);
		font-style:italic;
	}
}

.transition-box{
	position:fixed;
	z-index:1000;
	top:50%;
	left:50%;
	height:400%;
	width:400%;
	transform:translate(-50%, -50%);
	&--hidden{
		display:none;
	}
	&__wrapper{
		height:100%;
		width:100%;
		overflow:hidden;
		position:relative;
		@include clearfix();
		transform:rotate(45deg);
	}
	&__left,
	&__right{
		height:100%;
		width:50%;
		position:absolute;
		top:50%;
		transition:transform 0.7s ease-in;
	}
	&__left{
		background-color:$color-teal-lt;
		transform:translateY(30%);
	}
	&__right{
		background-color:#FEF8EB;
		left:50%;
		transform:translateY(-130%);
	}
	&--active-in{
		.transition-box{
			&__left{
				transform:translateY(-50%);
			}
			&__right{
				transform:translateY(-50%);
			}
		}
	}
	&--active-out{
		.transition-box{
			&__left{
				transform:translateY(-130%);
			}
			&__right{
				transform:translateY(30%);
			}
		}
	}
}

.main--inactive{
	opacity:0;
	transform:scale(0.75);
}

@media(max-width:767px){
	.desktop{
		display:none;
	}
	.mobile{
		display:block;
	}
}

@media(max-width:520px){
	.desktop--sm{
		display:none;
	}
	.mobile--sm{
		display:block;
	}
}