body {
  .learndash-wrapper .ld-content-action .learndash_mark_complete_button,
  .learndash-wrapper .ld-content-action #learndash_mark_complete_button,
  .learndash-wrapper .ld-content-action .ld-button:not(.learndash-link-previous-incomplete):not(.ld-button-transparent) {
    color:$color-neutral-dk;
    font-weight: 600;
    font-size: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 2px;
    transition:300ms ease;

    &:hover, &:focus, &:active {
      background:$color-salmon-lt !important;
      opacity:1;
      color:$color-neutral-dk;

      .ld-icon-arrow-right,
      .ld-icon-arrow-left {
        transform: none !important;
      }
    }
  }

  .learndash-wrapper .sfwd-mark-complete,
  .learndash-wrapper #sfwd-mark-complete {
    &:after {
      color:$color-neutral-dk;
    }

    &:hover, &:focus, &:active {
      &:after {
        color:$color-neutral-dk;
      }
    }
  }

  .learndash-wrapper .ld-breadcrumbs .ld-breadcrumbs-segments span a {
    color:$color-purple-dk !important;
    font-weight: 600;
    font-size:1rem;
    text-decoration: underline;

    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  }

  .learndash-wrapper a {
    text-decoration: underline;

    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  }

  .learndash-wrapper .ld-content-actions .ld-content-action .ld-course-step-back {
    color:$color-purple-dk !important;
    font-weight: 600;
    font-size:1rem;
  }

  .learndash-wrapper .ld-item-list .ld-item-list-item .ld-item-name {
    text-decoration: none;
    align-items: center;

    &.ld-primary-color-hover {
      &:hover, &:active, &:focus {
        color:$color-purple-dk !important;
        text-decoration: underline;
      }
    }
  }

  .learndash-wrapper .ld-item-list .ld-item-list-item .ld-item-list-item-preview .ld-status-icon {
    margin-top:0;
  }

  .learndash-wrapper .ld-content-actions .ld-primary-color {
    color:$color-purple-dk !important;
    font-weight: 600;
    font-size:1rem;
  }
}