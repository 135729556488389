.section {
    &--banner-extension {
        background: $color-teal-lt;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &--flush {
        padding: 0;
    }
    &--bg {
        padding: 180px 0;
        background-size: auto 100%;
        background-position: right center;
        background-repeat: no-repeat;
    }
    &--full {
        padding: 100px 0;
        padding-bottom: 200px;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        border-top: 1px solid $color-neutral;
        h2 {
            @include set-type(38, 600, 48);
        }
        p {
            @include set-type(18, 400, 24);
        }
        a {
            font-weight: 400;
        }
    }
    &--smaller {
        .container {
            width: 100%;
            max-width: 850px;
            background: $color-lt;
            padding: 60px 0 30px;
            .grid {
                padding: 30px 0;
                margin: 0 30px;
                border-top: 1px solid $color-grey;
                &:first-of-type {
                    padding-top: 0;
                    border-top: 0 none;
                }
            }
        }
    }
    &--team {
        padding: 60px 0;
        margin-top: 90px;
        h2 {
            @include set-type(38, 600, 48);
        }
        p {
            @include set-type(24, 400, 32);
        }
    }
    &--yellow {
        background-color: $color-bg;
        a {
            font-weight: 400;
        }
        h2 {
            +h3 {
                margin-top: 25px;
            }
        }
        &.section--alert .container {
            background: $color-peach;
        }
    }
    &--alert {
        padding-bottom: 60px;
        .container {
            padding: 20px 40px;
            img {
                max-width: 100px;
                margin: auto;
            }
            p {
                font-size: 22px;
                line-height: 1.3;
            }
        }
    }
    &--logos {
        .grid *[class*="grid__col"].single-logo {
            padding: 90px 40px 30px;
            box-shadow: 0px 0 5px rgba($color-neutral-dk, 0.3);
            border: 0 none;
            color: $color-neutral-dk;
            transition: 300ms ease;
            position: relative;
            img {
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
                transition: 300ms ease;
                &.color {
                    opacity: 0;
                }
            }
            p {
                line-height: 1.3;
                margin-top: 15px;
                font-weight: 600;
                font-size: 0.9em;
            }
            &:hover {
                box-shadow: 0px 0 8px rgba($color-neutral-dk, 0.3);
                img {
                    &.grey {
                        opacity: 0;
                    }
                    &.color {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &--teal {
        background-color: $color-teal-lt;
        a {
            font-weight: 600;
        }
        .container-teal {
            max-width: 950px;
        }
        .col-right {
            background-color: $color-teal-med;
            padding: 20px;
            img {
                max-width: 58px;
                height: auto;
                margin-top: 15px;
                margin-right: 15px;
            }
        }
    }
    &--blue {
        background-color: $color-bg-blue;
        a {
            font-weight: 600;
        }
        .container-teal {
            max-width: 950px;
        }
        .col-right {
            background-color: $color-blue-lt;
            padding: 20px;
            img {
                max-width: 58px;
                height: auto;
                margin-top: 15px;
                margin-right: 15px;
            }
        }
    }
    &--ebook {
        position: relative;
        background: $color-salmon-lt url('../../images/triangle-background.png') no-repeat right bottom/auto 100%;
        .section--padded {
            padding-top: 70 px;
            padding-bottom: 70px;
        }
        p {
            margin-bottom: 30px;
        }
        img {
            width: 90%;
            max-width: 300px;
            float: right;
        }
        .button--primary:hover {
            background-color: $color-salmon;
        }
    }
    &--homepage--callout {
        background: $color-salmon-lt url('../../images/background_cta.png') no-repeat center center;
        background-size: cover;
        h2 {
            font-size: 2.5em;
        }
        h3 {
            margin: 0 0 30px;
            font-weight: 400;
            font-size: 1.6em;
        }
    }
    &--title {
        padding: 60px 0 0;
        h2 {
            line-height: 1.3;
        }
    }
    &--padded {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &--padded-lg {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    &--repeater {
        padding: 200px 0;
        &.small--spacing {
            padding:90px 0;
        }
        +.section--repeater {
            padding-top: 60px;
        }
        *[class*="grid__col"] {
            &.section__content {
                padding: 0 9%;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            li {
                padding: 10px 0;
                padding-left: 45px;
                position: relative;
                i {
                    position: absolute;
                    left: 0;
                    top: 6px;
                    background: $color-salmon;
                    border-radius: 50px;
                    height: 30px;
                    width: 30px;
                    padding: 7px;
                }
            }
        }
    }
    &--split {
        position: relative;
        padding: 0;
        h2 {
            a {
                font-weight: 400;
                @include remove-link-interaction();
                display: inline;
                border-bottom: 1px solid $color-purple-dk;
                color: $color-purple-dk;
                transition: border 0.2s ease-in-out;
                &:hover,
                &:focus {
                    border-bottom: 1px solid transparent;
                }
            }
        }
        .grid {
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 33.333%;
            top: 0;
        }
        &-left {
            &:after {
                left: 0;
            }
        }
        &-right {
            &:after {
                right: 0;
            }
        }
        &-grey {
            background: rgba($color-purple-lt, 0.5);
            &:after {
                background: $color-purple-lt;
            }
        }
        &-yellow {
            background: $color-green-lt;
            &:after {
                background: $color-yellow-lt;
            }
        }
        &-green {
            background: #e5f5ee;
            &:after {
                background: $color-teal-lt;
            }
        }
        &__content-wrapper {
            padding: 200px 120px;
            position: relative;
            z-index: 3;
        }
        &__image-wrapper {
            position: relative;
            img {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                max-height: 100%;
                max-width: none;
                z-index: 2;
            }
        }
    }
    &--3-cols {
        .grid {
            margin-left: -30px;
            margin-right: -30px;
            .grid__col--md-4 {
                padding-left: 30px;
                padding-right: 30px;
                img {
                    width: 120px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__title {
        margin-bottom: 90px;
    }
    &__image-wrapper {
        position: relative;
    }
    &__image {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 15px;
    }
    &__content-wrapper {
        position: relative;
        .section {
            &__content {
                &--centered {
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    transform: translateY(-50%);
                }
            }
        }
    }
    &--page-content {
        padding-top: 132px;
    }
    &--page-intro {
        padding: 60px 0;
        padding-bottom: 30px;
        p {
            @include set-type(20,
            400,
            28);
        }
    }
    &--features {
        padding: 90px 0;
        &__header {
            margin-bottom: 60px;
        }
        &__title {
            @include set-type(32,
            600,
            34);
        }
        &__image {
            display: inline-block;
            margin-top: 30px;
            max-width: 100%;
        }
        &__icon {
            display: inline-block;
            margin-bottom: 30px;
            width: 100px;
        }
        &__image-wrapper {
            position: relative;
            img {
                &:only-child {
                    display: block;
                }
            }
            .list {
                position: absolute;
                width: 100%;
                top: 245px;
                left: 0;
                height: 75px;
                overflow: hidden;
                li {
                    margin: 10px;
                }
            }
        }
    }
    &--column-section {
        >.grid {
            margin-left: -30px;
            margin-right: -30px;
        }
        .column {
            margin-bottom: 40px;
            padding-left: 30px;
            padding-right: 30px;
            img {
                max-width: 80px;
                margin-left: auto;
                margin-right: auto;
            }
            a {
                color: $color-dk;
            }
        }
    }
    &--prices {
        padding-bottom: 60px;
    }
    &--integrations {
        padding: 60px 0;
    }
    &--testimonial {
        padding-top: 30px;
    }
    &--touchpoints {
        padding-top: 90px;
        padding-bottom: 0;
        h2 {
            @include set-type(42,
            600,
            54);
        }
        p {
            @include set-type(20,
            400,
            32);
        }
    }
    &-career {
        &-value {
            h2 {
                margin-bottom: 49px;
            }
            ol {
                font-size: 20px;
                li {
                    font-weight: 100;
                    margin-bottom: 20px;
                    &:before {
                        color: $color-neutral-dk;
                    }
                }
            }
        }
        &-life {
            padding-top:100px;
            padding-bottom:90px;
            h2.section__title {
                margin-bottom: 40px;
            }
            .bucket {
                background: $color-lt;
                padding-top: 40px;
                margin-right: 26px;
                &:last-child {
                    margin-right: 0;
                }
            }
            .bucket__content {
                margin-bottom: 40px;
                h3 {
                    line-height: 24px;
                }
                p {
                    line-height: 24px;
                }
            }
        }
        &-hire {
            h2 {
                margin-top: 84px;
                margin-bottom: 45px;
            }
            .hire-accordion {
                margin-top: 50px;
                ol {
                    padding: 0;
                    li {
                        background: $color-lt;
                        padding: 15px;
                        &:before {
                            content:counter(custom-counter);
                            color: $color-neutral-dk;
                            width: 30px;
                            height: 30px;
                            background: $color-teal-lt;
                            border: 2px solid $color-teal-lt;
                            border-radius: 20px;
                            text-align: center;
                            left: auto;
                            top: auto;
                        }
                        .accordion-content {
                            padding-left: 60px;
                            .accordion-content-heading {
                                position: relative;
                                margin-bottom: 0;
                                padding:0;
                                font-size: 22px;
                                line-height: 30px;
                                border: 0;
                                background: transparent;
                                font-weight: 600;
                                width: 100%;
                                text-align: left;
                                cursor: pointer;
                                vertical-align: middle;
                            }
                            .panel {
                                display: none;
                                overflow: hidden;
                                margin-top: 15px;
                            }
                            .accordion-indicator {
                                position: absolute;
                                top: 50%;
                                right: 15px;
                                pointer-events: none;
                                transform:translateY(-50%);
                                line-height: 1;
                                font-size:16px;
                            }
                        }
                    }
                }
            }
        }
        &-job {
            h2 {
                margin-top: 70px;
                margin-bottom: 40px;
            }
            p {
                margin-bottom: 60px;
            }
            .job-repeater-container {
                .job-repeater {
                    justify-content: space-between;
                    background: $color-teal-lt;
                    padding: 15px;
                    margin:0 0 15px;
                    width:100%;
                    a {
                        text-decoration: none;
                        color: $color-link-dk;
                        border: 0;
                        &:hover,
                        &:active,
                        &:focus {
                            text-decoration: underline;
                        }
                    }
                    .job-repeater-link {
                        a {
                            i {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        &-testimony {
            padding-top:70px;
            padding-bottom:90px;
            .testimony {
                position: relative;
                &-slide {
                    display: none;
                    &:first-child {
                        display: block;
                    }
                }
                &-quote {
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 54px;
                    margin-bottom: 68px;
                }
                &-image {
                    padding-right: 0;
                    img {
                        width: 91px;
                        height: 91px;
                        object-fit: cover;
                    }
                }
                &-person {
                    height: 91px;
                    justify-content: center;
                    flex-direction: column;
                    padding-left: 0;
                    strong {
                        margin-bottom: 5px;
                    }
                }
                button {
                    position: absolute;
                    top: 45%;
                    background: $color-salmon;
                    border: 0;
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    border-radius: 100%;
                    font-size: 17px;
                    cursor: pointer;
                    transform:translateY(-45%);
                }
                #previous-slider {
                    left: 0;
                }
                #next-slider {
                    right: 0;
                }
            }
        }
        &-blog {
            .blog-card {
                padding:0 15px;
                .card {
                    height:100%;
                }
                .blog-card-image {
                    margin-bottom: 15px;
                }
                a {
                    color: $color-neutral-dk;
                    text-decoration: none;
                    border: 0;
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: underline;
                    }
                }
                &-content {
                  padding:0 10px;
                  @include set-type(18, 700, 24);
                }
            }
        }
        &-badges {
            .card-container {
                padding:0 15px;
                margin-bottom: 30px;
                .card{
                    height: 100%;
                    padding:30px 15px;
                    img {
                        max-height: 200px;
                        width:auto;
                        margin:0 auto;
                    }
                }
            }
        }
        &-apply {
            background: $color-bg url('../../images/Bottom-CTA-no-bg-colourx2.png') no-repeat center center;
            background-size: cover;
            .apply-content {
                padding-right: 0;
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }
    }
}

.career {
    h2 {
        font-size: 30px;
        line-height: 40px;
    }
    p {
        font-size: 18px;
        line-height: 30px;
    }
    h3 {
        font-size: 22px;
        line-height: 30px;
    }
}

.banner {
    +.section--page-content {
        padding-top: 30px;
    }
}

@media(max-width:768px) {
    .section {
        &--repeater {
            padding: 45px 0;
            +.section--repeater {
                padding-top: 0;
            }
        }
        &--padded-lg {
            padding: 45px 0;
        }
        &--testimonial {
            padding-top: 0;
        }
        &--smaller {
            .container {
                background-color: transparent;
            }
        }
        &--split {
            position: relative;
            h2 {
                @include set-type(28,
                600,
                32);
            }
            .grid {
                position: static;
            }
            &__content-wrapper {
                padding: 20px 15px;
            }
            &__image-wrapper {
                position: static;
                img {
                    height: 100%;
                    width: auto;
                    z-index: 2;
                    transform: translateX(0);
                }
            }
            *[class*="grid__col"] {
                margin-bottom: 0;
            }
            &-grey {
                .section--split__image-wrapper {
                    background: $color-purple-lt;
                }
            }
            &-yellow {
                .section--split__image-wrapper {
                    background: $color-yellow-lt;
                }
            }
            &-green {
                .section--split__image-wrapper {
                    background: $color-teal-lt;
                }
            }
        }
        &--split-right {
            padding-right: 0;
            &:after {
                display: none;
            }
            .section--split__image-wrapper {
                padding-top: 25px;
                img {
                    position: static;
                    width: 100%;
                    height: auto;
                    margin-left: 10%;
                }
            }
        }
        &--split-left {
            padding-left: 0;
            &:after {
                display: none;
            }
            .section--split__image-wrapper {
                padding-top: 25px;
                img {
                    position: static;
                    width: 100%;
                    height: auto;
                    margin-right: 10%;
                }
            }
        }
        &--features {
            &__image-wrapper {
                .list {
                    margin-top: 30px;
                    position: static;
                    height: auto;
                    overflow: visible;
                    top: 0;
                }
            }
        }
        &--bg {
            padding: 30px 0;
            &[style] {
                background-image: none!important;
            }
        }
        &--integrations {
            padding-bottom: 0;
        }
        &__image-wrapper {
            text-align: center;
            img {
                position: static;
                width: auto;
                max-width: 100%;
                display: inline-block;
                transform: none;
            }
        }
        &__title {
            margin-bottom: 45px;
        }
        &__content-wrapper {
            .section {
                &__content {
                    &--centered {
                        position: static;
                        top: auto;
                        transform: translate(0, 0);
                    }
                }
            }
        }
        &--logos {
            .grid *[class*="grid__col"].single-logo {
                margin: 0 auto 20px;
                width: 90%;
                max-width: 425px;
                img {
                    &.grey {
                        display: none;
                    }
                    &.color {
                        opacity: 1;
                    }
                }
            }
        }
        &-career {
            &-life {
                .life-container {
                    margin-bottom: 0;
                }

                .bucket {
                    padding: 30px 20px 15px;
                    max-width: 90%;
                    margin: 0 auto 30px;
                    &:last-child {
                        margin: 0 auto 30px;
                    }
                }
            }
            &-job {
                .job-repeater-container {
                    .job-repeater {
                        .job-repeater-heading {
                            margin-bottom: 10px;
                        }
                        .job-repeater-link {
                            position: relative;
                            text-align: left;
                        }
                    }
                }
            }
            &-testimony {
                padding-top:20px;
                padding-bottom:40px;
                .testimony {
                    .testimony-container {
                        padding-bottom: 60px;
                    }
                    .testimony-quote {
                        font-size:20px;
                        line-height: 34px;
                        margin-bottom: 40px;
                    }
                    &-slide {
                        margin: 0 10px;
                    }
                    #previous-slider {
                        top:auto;
                        bottom:0;
                        left: 20%;
                    }
                    #next-slider {
                        top:auto;
                        bottom:0;
                        right:20%;
                    }
                }
            }
            &-blog {
                .blog-card {
                    margin: 0 auto 20px auto;
                    width: 80%;
                    text-align: center;
                }
            }
        }
    }
}

@media(max-width:600px) {
    .section--ebook {
        background-size: auto 40%;
    }
}