.card{
	box-shadow:none;
	border:none;
	&--flush{
		margin:0;
		border-bottom:1px solid $color-neutral;
		&:last-of-type{
			border-bottom:none;
		}
	}
	p{
		@include set-type(18, 400, 24);
	}
	a{
		color:$color-purple-dk;
		font-weight:400;
		@include link-interaction($color-purple-dk, 'true');
	}
	&--dk{
		background:$color-neutral-dk;
		color:$color-bg;
		h2{
			color:$color-bg;
			@include set-type(42, 600, 48);
			margin-bottom:30px;
		}
		p{
			@include set-type(22, 400, 36);
		}
		a{
			color:$color-teal-lt;
			@include link-interaction($color-teal-lt, 'true');
		}
		.card{
			&__content{
				padding:80px;
			}
		}
	}
	&__content{
		padding:60px;
	}
}

@media(max-width:767px){
	.card{
		&--dk{
			h2{
				@include set-type(36, 600, 42);
			}
			.card{
				&__content{
					padding:30px;
				}
			}
		}
	}
}