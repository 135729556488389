.sidebar{
	padding-top:0;
	padding-bottom:0;
	&__label{
		display:block;
		@include set-type(18, 600, 24);
		margin-bottom:30px;
	}
}

@media(max-width:767px){
	.sidebar{
		&__label{
			margin-bottom:15px;
		}
	}
}