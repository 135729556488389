@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f177" !default;
$slick-next-character: "\f178" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
}

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: none !important;
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Arrows */
.slider--nav {
    position: absolute;
    bottom:0;
    right: 0;
    display: flex;
}
.slider--nav .prev,
.slider--nav .next {
    display: inline-block;
    height: 60px;
    width: 60px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: $color-salmon;
    color: transparent;
    padding: 0;
    border: none;
    outline: none;
    z-index: 900;
    &:hover, &:focus {
        outline: none;
        background: $color-salmon-lt;
        color:transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-size: 20px;
        line-height: 60px;
        color:$color-neutral-dk;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slider--nav .prev {
    &:before {
        font-family: 'Font Awesome\ 5 Pro';
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slider--nav .next {
    &:before {
        font-family: 'Font Awesome\ 5 Pro';
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */


.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

.slick-logo {
    position: relative;
    width:200px;
    height: 100px;
    text-align: center;
    img {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        transition:300ms ease;
        max-width: 120px;

        &.color {
            opacity: 0;
        }
    }
    &:hover {
        img {
            &.grey {
                opacity: 0;
            }

            &.color {
                opacity: 1;
            }
        }
    }
}

.slick-current {
    .slick-logo {
        img {
            &.grey {
                opacity: 0;
            }

            &.color {
                opacity: 1;
            }
        }
    }
}

.slick-slide {
    .slider--content {

        &-logo {
            background-color: $color-bg;
            position: relative;

            a, a:hover, a:focus, a:active {
                border:0 none;
            }

            img {
                max-width: 190px;
            }
        }

        &-desc {
            background-color: transparent;
            position: relative;
            overflow: hidden;

            .bg-color {
                background-color: transparent;
                position: absolute;
                width:0;
                height:100%;
                left:0;
                top:0;
            }

            .old-bg-color {
                position: absolute;
                width:100%;
                height:100%;
                right:0;
                top:0;
            }

            .desc {
                padding:0 30px;

                p {
                   font-size: 1.6em;
                   line-height: 1.3;
                }

                a{
                    display:flex;
                    font-size: 1.2em;
                    font-weight: 400;
                    color: $color-neutral-dk;
                    border:0 none;
                    vertical-align: middle;

                    span {
                        border-bottom:1px solid $color-neutral-dk;
                        margin-right: 5px;
                    }
                }

                p, a{
                    transform: translateX(-500px);
                    opacity: 0;
                }

                a i {
                    transform: translateX(-50px);
                    opacity: 0;
                }
            }
        }

        &-logo, &-desc {
            height: 280px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    &:nth-child(4n-7) {
        .slider--content-desc .bg-color{
            background:$color-salmon-lt;
        }
    }

    &:nth-child(2n+2) {
        .slider--content-desc .bg-color{
            background: $color-purple-lt;
        }
    }

    &:nth-child(3n+3) {
        .slider--content-desc .bg-color{
            background:$color-teal-lt;
        }
    }

    &:nth-child(4n+4) {
        .slider--content-desc .bg-color{
            background:$color-blue-lt;
        }
    }
}

.slider-content {
    .slick-slide {


        &.slick-current {
            .slider--content-desc {

                .bg-color {
                    animation: widthSlideLeft 775ms linear;
                    animation-fill-mode: both;
                    animation-delay:300ms;
                }

                .old-bg-color {
                    animation: widthSlideLeftOld 775ms linear;
                    animation-fill-mode: both;
                    transition:none;
                    width:0%;
                    animation-delay:305ms;
                }
                


                .desc {
                    p, a, a i {
                        animation: slideLeft 775ms ease;
                        animation-fill-mode: both;
                    }

                    p {animation-delay:500ms;}
                    a {animation-delay:750ms;}
                    a i {animation-delay:775ms;}
                }
            }
        }

    }
}

@media screen and (max-width: 991px) {
    .slider-nav {
        display:none;
    }

    .slider--nav {
        right:auto;
        left:20px
    }
}

