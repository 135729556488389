@keyframes FadeIn { 
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes slideLeft { 
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }
  10% {
  	opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes widthSlideLeft {
  0% {
    left:0;
    width:0%;
  }
  50% {
    width:50%;
  }
  100% {
    left:0;
    width:100%;
  }
}

@keyframes widthSlideLeftOld {
  0% {
    right:0;
    width:100%;
  }
  50% {
    width:50%;
  }
  100% {
    right:0;
    width:0%;
  }
}