.banner {
    background: transparent;
    padding-top: 162px;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
    transition: padding 0.2s ease-in-out;
    .container {
        position: relative;
        z-index: 2;
    }
    &--front {
        background-color: $color-teal-lt;
        .banner {
            &__image-wrapper {
                position: relative;
                min-height: 500px;
            }
            &__image {
                position: absolute;
                z-index: -1;
                left: 15px;
                bottom: 0;
                max-width: none;
            }
            &__bg {
                position: absolute;
                width: 55%;
                height: 200%;
                right: 0;
                top: 0;
                background: $color-teal-lt;
                z-index: 1;
                &:after {
                    content: '';
                    position: absolute;
                    top: -10%;
                    right: -20%;
                    width: 75%;
                    height: 100%;
                    background: $color-bg;
                    transform: rotate(35deg);
                }
            }
            &__content {
                padding-top: 120px;
                padding-bottom: 120px;
                .fadein-element {
                    transform: translateY(100px);
                    opacity: 0;
                    animation: FadeIn 800ms ease;
                    animation-fill-mode: both;
                    &:nth-child(1) {
                        animation-delay: 100ms
                    }
                    &:nth-child(2) {
                        animation-delay: 400ms
                    }
                    &:nth-child(3) {
                        animation-delay: 800ms
                    }
                }
            }
        }
    }
    &--sub {
        background: $color-bg;
        padding-bottom: 60px;
        .banner {
            &__image-wrapper {
                text-align: center;
                position: relative;
                img {
                    display: block;
                    max-width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                &-lower {
                    img {
                        bottom: -50px;
                    }
                }
            }
            &__content {
                padding-top: 120px;
                padding-bottom: 120px;
            }
            &__bg {
                height: 150px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #F5E3C7;
            }
        }
    }
    &--cta {
        .banner__content {
            padding-top: 75px;
            padding-bottom: 60px;
            h1 {
                font-size: 40px;
                margin-bottom: 30px;
            }
            h2 {
                font-size: 1.7em;
                margin-top: -20px;
                margin-bottom: 30px;
                font-weight: 400;
            }
        }
        .banner__image--right {
            position: absolute;
            right: 0;
            bottom: 10%;
            width: auto;
            max-width: 600px;
            transform: translateY(10%);
            img {
                max-width: 100%;
            }
        }
    }
    &--integration {
        background: $color-bg;
        padding-bottom: 60px;
        margin-bottom: 45px;
        .banner {
            &__image {
                display: block;
                margin: 0 auto;
                width: 100%;
                max-width: 300px;
            }
        }
    }
    &--compact {
        .banner__content {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
    &--no-floor {
        .banner {
            &__image-wrapper {
                img {
                    bottom: auto;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    &--minimal {
        background: $color-lt;
        h1,
        h2 {
            margin-bottom: 60px;
            &:only-child {
                margin-bottom: 0;
            }
        }
        .banner__image {
            max-width: 350px;
            margin: 0 auto;
            display: block;
        }
    }
    &--default {
        +.section--page-content {
            margin-top: 15px;
        }
    }
    &--testimonial {
        position: relative;
        padding: 0;
        padding-top: 120px;
        .grid {
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            top: -10%;
            width: 50%;
            height: 200%;
            top: 0;
        }
        &-left {
            &:after {
                left: -20%;
                transform: rotate(-35deg);
            }
            .banner--testimonial__content-wrapper {
                padding-left: 120px;
            }
        }
        &-right {
            &:after {
                right: -20%;
                transform: rotate(35deg);
            }
            .banner--testimonial__content-wrapper {
                padding-right: 120px;
            }
            .banner--testimonial__image-wrapper {
                img {
                    left: -8%;
                }
            }
        }
        &-grey {
            background: rgba($color-purple-lt, 0.5);
            &:after {
                background: $color-purple-lt;
            }
        }
        &-yellow {
            background: $color-green-lt;
            &:after {
                background: $color-yellow-lt;
            }
        }
        &-green {
            background: #e5f5ee;
            &:after {
                background: $color-teal-lt;
            }
        }
        &__content-wrapper {
            padding: 200px 15px;
            position: relative;
            z-index: 3;
        }
        &__image-wrapper {
            position: relative;
            img {
                position: absolute;
                bottom: 0;
                left: 0;
                max-height: 100%;
                z-index: 2;
            }
        }
    }
    &__title {
        @include set-type(56, 600, 62);
        +.banner__subtitle {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        .highlight {
            display:inline-block;
            position:relative;

            &:after {
                display: block;
                content:"";
                width:100%;
                height:15px;
                background:$color-yellow;
                bottom:7px;
                left:57%;
                transform:translateX(-55%);
                position: absolute;
                z-index: -1;
            }
        }
    }
    &__subtitle {
        display: block;
        @include set-type(24, 400, 28);
        +.banner__title {
            margin-top: 15px;
        }
    }
    p {
        @include set-type(20, 300, 28);
    }
    &-career {
        background-color: $color-bg;
        &-cover {
            background-color: $color-teal-lt;
            clip-path: polygon(97% 0, 100% 0, 100% 100%, 50% 100%);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }
        .banner__heading p {
            font-size: 40px;
            line-height: 45px;
            margin-bottom: 49px;
            font-weight: 600;
        }
        .banner__body p {
            margin-bottom: 35px;
        }
        .grid {
            justify-content: space-between;
        }
        .banner__image-wrapper {
            height: 272px;
            width: 161px;
            min-height: 400px;
            img {
                width: inherit;
                height: inherit;
                object-fit: cover;
            }
        }
    }
}

.landing-banner {
    @media(min-width:768px) {
        .banner--no-floor .banner__image-wrapper img {
            scale:1.2;
            top:60%;
            left:60%;
        }
    }
}

@media(max-width:767px) {
    .banner {
        padding-top: 102px;
        &__title {
            @include set-type(48, 600, 54);
        }
        &__content {
            padding: 30px 0;
        }
        .grid--flex {
            display: block;
        }
        &--front {
            .banner {
                &__image-wrapper {
                    display: none;
                }
                &__bg {
                    width: 100%;
                }
            }
        }
        &--sub {
            .banner {
                &__content {
                    padding: 60px 0;
                }
                &__image-wrapper {
                    height: auto;
                    img {
                        position: static;
                        transform: translateX(0);
                        margin: 0 auto;
                        height: auto;
                        left: 50%;
                        max-width: 100%;
                    }
                }
            }
        }
        &--no-bg {
            .banner {
                &__image-wrapper {
                    img {
                        margin-left: 0;
                    }
                }
            }
        }
        &--testimonial {
            position: relative;
            h1 {
                @include set-type(28, 600, 32);
            }
            .grid {
                position: static;
            }
            &__content-wrapper {
                padding: 30px 15px;
            }
            &__image-wrapper {
                position: static;
                img {
                    height: 100%;
                    width: auto;
                    z-index: 2;
                }
            }
            *[class*="grid__col"] {
                margin-bottom: 0;
            }
            &-grey {
                &:after {
                    display: none;
                }
                .banner--testimonial__image-wrapper {
                    background: $color-purple-lt;
                }
            }
            &-yellow {
                &:after {
                    display: none;
                }
                .banner--testimonial__image-wrapper {
                    background: $color-yellow-lt;
                }
            }
            &-green {
                &:after {
                    display: none;
                }
                .banner--testimonial__image-wrapper {
                    background: #e5f5ee;
                }
            }
        }
        &--testimonial-right {
            padding-right: 0;
            .banner--testimonial__content-wrapper {
                padding-right: 15px;
            }
            .banner--testimonial__image-wrapper {
                padding-top: 25px;
                img {
                    position: static;
                    width: 100%;
                    margin-left: 10%;
                }
            }
        }
        &--testimonial-left {
            padding-left: 0;
            .banner--testimonial__content-wrapper {
                padding-left: 15px;
            }
            .banner--testimonial__image-wrapper {
                padding-top: 25px;
                img {
                    position: static;
                    width: 100%;
                    margin-right: 10%;
                }
            }
        }
        *[class*="grid__col"] {
            margin-bottom: 0;
        }
    }
    .scrolled {
        .banner {
            padding-top: 30px;
        }
    }
}

@media (max-width: 768px) {
    .banner--cta {
        .banner__content {
            padding-top: 75px;
            padding-bottom: 60px;
            h1 {
                font-size: 24px;
            }
            h2 {
                font-size: 1.2em;
                margin-top: -20px;
                margin-bottom: 30px;
                font-weight: 400;
            }
        }
        .banner__image--right {
            max-width: 300px;
        }
    }
}