.price-table{
	width:100%;
	&__support-level{
		@include set-type(20, 400, 28);
	}
	&__price{
		@include set-type(26, 600, 34);
		margin-bottom:10px;
	}
	&__price-interval{
		font-size:0.7em;
		opacity:0.8;
	}
	thead{
		tr{
			background:transparent;
			th{
				background:$color-neutral-lt;
				color:$color-neutral-dk;
				text-align:center;
				padding:15px;
				border:1px solid darken($color-neutral-lt, 10%);
				@include set-type(20, 600, 28);
				&.price-table{
					&__heading{
						&--no-bg{
							background:transparent;
							border:none;
						}
					}
				}
			}
		}
	}
	tbody{
		tr{
			&:first-of-type{
				td{
					padding:30px 15px;
					p{
						line-height:1.3em;
					}
				}
			}
			&:nth-of-type(2n){
				background:transparent;
				th{
					background:lighten($color-teal-lt, 10%);
				}
			}
			th{
				background:lighten($color-teal-lt, 5%);
				width:34%;
				padding:15px 45px;
				color:$color-neutral-dk;
				font-weight:600;
				text-align:left;
				&.price-table{
					&__heading{
						&--no-bg{
							background:transparent;
						}
					}
				}
			}
			td{
				border:1px solid darken($color-neutral-lt, 10%);
				border-top:none;
				border-bottom:none;
				text-align:center;
				width:22%;
				vertical-align:middle;
				i{
					font-size:1.4em;
					&.fa-times{
						color:$color-neutral;
					}
				}
			}
		}
	}
	tfoot{
		tr{
			td{
				text-align:center;
				padding:30px 15px;
				border:1px solid darken($color-neutral-lt, 10%);
				border-top:none;
				width:22%;
			}
		}
	}
	// Styles for responsive design
	td + td,
	th + th,
	th + td{
	  display:none;
	}
	td.default,
	th.default{
	  display:table-cell;
	}
	th{
		display:none;
	}
	thead{
		th{
			&:first-child{
				display:table-cell;
			}
		}
	}
	tbody th,
	tfoot th{
		display:table-cell;
	}
	&__controls{
		list-style:none;
		padding:0;
		display:flex;
		margin:0 -5px;
		li{
			display:block;
			width:100%;
			padding:0 5px;
			&.active{
				.button{
					outline:5px solid $color-neutral-dk;
					outline-offset:-5px;
				}
			}
		}
		.button{
			background:$color-neutral-lt;
			color:$color-neutral-dk;
			text-align:center;
			padding:15px;
			border:1px solid darken($color-neutral-lt, 10%);
			@include set-type(20, 600, 28);
			margin:0;
			width:100%;
		}
	}
}

.product-table {
	margin-top:50px;
	border-bottom:1px solid darken($color-neutral-lt, 10%);

	tbody{
		tr{
			&:first-of-type{
				td{
					padding:15px;
				}
			}
		}
	}

	thead {
		tr { 
			th{
				background-color: $color-salmon;
			}
		}
	}
}

table.aligncenter{
	margin:0 auto;
}

@media(max-width:767px){
	.price-table{
		tbody{
			tr{
				&:first-of-type{
					td{
						border-top:1px solid darken($color-neutral-lt, 10%);
					}
				}
				td{
					width:50%;
					border-right:1px solid darken($color-neutral-lt, 10%);
				}
				th{
					width:50%;
					padding:15px;
				}
			}
		}
	}
	.table__wrapper{
		&--mobile-overflow{
			overflow:visible;
		}
	}
}

@media (min-width: 768px) {
  .price-table{
  	thead{
  		th{
  			&.webaim-hidden{
  				@include set-webaim-hidden-false();
  				z-index:1;
  			}
  		}
  	}
  	&__controls{
			display:none;
  	}
  	td,
  	th{
  		display:table-cell !important;
  	}
  }
}