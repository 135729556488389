footer{
	background:transparent;
	padding:60px 0;
	border-top:1px solid $color-neutral;
	color:$color-neutral-dk;
}

.prefooter{
	padding:60px 0;
	border-top:1px solid $color-neutral;
	h2{
		margin-bottom:30px;
	}
}

[class*="grid__col"] img.trusted-footer-logo {
	max-width: 250px;
	margin-top: 20px;
}


@media(max-width:767px){
	footer{
		text-align:center;
	}
}