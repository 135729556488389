.grid{
	+ .button{
		margin:0;
		margin-top:30px;
	}
}

button{
	font-family:$font-body-family;
}

.button{
	box-shadow:none;
	border-radius:2px;
	font-family:$font-body-family;
	@include remove-link-interaction();
	&--primary{
		background:$color-brand-1;
		color:$color-neutral-dk;
		font-weight:600;
		&:hover,
		&:focus{
			background:$color-brand-1-lt;
			color:$color-neutral-dk;
		}
	}
	&--secondary{
		background:$color-teal-lt;
		color:$color-neutral-dk;
		font-weight:400;
		&:hover,
		&:focus{
			background:$color-teal;
		}
	}
	&--light{
		background:$color-lt;
		color:$color-neutral-dk;
		font-weight:600;
		&:hover,
		&:focus{
			background:$color-lt;
			color:$color-neutral-dk;
			text-decoration: underline;
		}
	}
	&--mobile-toggle{
		margin:0;
		padding:0;
		color:$color-neutral-dk;
		background:transparent;
		&.open{
			.mobile-toggle{
				&__bar{
					&:nth-of-type(1){
						transform:rotate(45deg);
						top:50%;
					}
					&:nth-of-type(2){
						opacity:0;
					}
					&:nth-of-type(3){
						transform:rotate(-45deg);
						top:50%;
					}
				}
			}
		}
	}
	&--pills{
		border-radius:50px;
		margin:15px;
	}
}

a.wp-block-button__link{
	border-bottom:none;
	&:hover,
	&:focus{
		border-bottom:none;
	}
}

.mobile-toggle{
	&__content{
		height:30px;
		width:35px;
		position:relative;
	}
	&__bar{
		display:block;
		height:2px;
		width:100%;
		background:$color-neutral-dk;
		position:absolute;
		left:0;
		transition:opacity 0.2s ease-in-out, transform 0.2s ease-in-out, top 0.2s ease-in-out, background 0.2s ease-in-out;
		&:nth-of-type(1){
			top:0;
		}
		&:nth-of-type(2){
			top:50%;
			transform:translateY(-50%);
		}
		&:nth-of-type(3){	
			top:100%
		}
	}
}
