.touchpoints{
	&__image{
		display:block;
		margin:0 auto;
	}
	&__image-wrapper{
		position:relative;
		padding-top:350px;
		margin-top:60px;
	}
}

.touchpoint{
	&__description{
		display:block;

		p {
			font-size: 1em;
			margin:0;
		}
	}
}

.list{
	&--touchpoints{
		list-style:none;
		padding:0;
		.touchpoint__icon{
			max-width:60px;
			display:block;
			margin:0 auto;
			margin-bottom:15px;
		}
		.touchpoint__image{
			display:block;
			margin:0 auto;
			width:100%;
			max-width:160px;
			margin-top:15px;
			margin-bottom:30px;
		}
		li{
			position:absolute;
			width:230px;
			margin-bottom:0;
			.touchpoint__arrow{
				display:inline-block;
				position:relative;
			}
			&:nth-of-type(1){
				left:0;
				bottom:30%;
				transform:translateX(-100%);
				padding:0 30px;
				.touchpoints__arrow{
					width:100%;
					display:block;
					height:1px;
					position:relative;
					border:1px dashed $color-neutral;
					&:before{
						content:'';
						height:10px;
						width:10px;
						border-radius:50px;
						background:$color-neutral;
						position:absolute;
						left:-1px;
						top:50%;
						transform:translateY(-50%);
					}
					&:after{
						content:'';
						width: 0; 
					  height: 0; 
					  border-top: 10px solid transparent;
					  border-bottom: 10px solid transparent;
					  border-left: 15px solid $color-neutral;
					  position:absolute;
					  right:-5px;
					  top:50%;
					  transform:translateY(-50%);
					}
				}
			}
			&:nth-of-type(2){
				left:0;
				top:0;
				transform:translateX(-75%);
				.touchpoints__arrow{
					display:inline-block;
					width: 0; 
				  height: 0; 
				  border-right: 10px solid transparent;
				  border-top: 15px solid $color-neutral;
				  border-left: 10px solid transparent;
				}
			}
			&:nth-of-type(3){
				left:20%;
				top:60px;
				transform:translateX(-50%);
				.touchpoints__arrow{
					width:1px;
					display:inline-block;
					height:200px;
					position:relative;
					border:1px dashed $color-neutral;
					&:before{
						content:'';
						height:10px;
						width:10px;
						border-radius:50px;
						background:$color-neutral;
						position:absolute;
						left:50%;
						top:-1px;
						transform:translateX(-50%);
					}
					&:after{
						content:'';
						width: 0; 
					  height: 0; 
					  border-top: 15px solid $color-neutral;
					  border-right: 10px solid transparent;
					  border-left: 10px solid transparent;
					  position:absolute;
					  left:50%;
					  bottom:-5px;
					  transform:translateX(-50%);
					}
				}
			}
			&:nth-of-type(4){
				left:50%;
				top:100px;
				transform:translateX(-50%);
				.touchpoints__arrow{
					display:inline-block;
					width: 0; 
				  height: 0; 
				  border-right: 10px solid transparent;
				  border-top: 15px solid $color-neutral;
				  border-left: 10px solid transparent;
				}
			}
			&:nth-of-type(5){
				left:80%;
				top:60px;
				transform:translateX(-50%);
				.touchpoints__arrow{
					width:1px;
					display:inline-block;
					height:200px;
					position:relative;
					border:1px dashed $color-neutral;
					&:before{
						content:'';
						height:10px;
						width:10px;
						border-radius:50px;
						background:$color-neutral;
						position:absolute;
						left:50%;
						top:-1px;
						transform:translateX(-50%);
					}
					&:after{
						content:'';
						width: 0; 
					  height: 0; 
					  border-top: 15px solid $color-neutral;
					  border-right: 10px solid transparent;
					  border-left: 10px solid transparent;
					  position:absolute;
					  left:50%;
					  bottom:-5px;
					  transform:translateX(-50%);
					}
				}
			}
			&:nth-of-type(6){
				left:100%;
				top:0;
				transform:translateX(-25%);
				.touchpoints__arrow{
					display:inline-block;
					width: 0; 
				  height: 0; 
				  border-right: 10px solid transparent;
				  border-top: 15px solid $color-neutral;
				  border-left: 10px solid transparent;
				}
			}
			&:nth-of-type(7){
				left:100%;
				bottom:30%;
				padding:0 30px;
				
				.touchpoints__arrow{
					width:100%;
					display:block;
					height:1px;
					position:relative;
					border:1px dashed $color-neutral;
					&:before{
						content:'';
						height:10px;
						width:10px;
						border-radius:50px;
						background:$color-neutral;
						position:absolute;
						right:-1px;
						top:50%;
						transform:translateY(-50%);
					}
					&:after{
						content:'';
						width: 0; 
					  height: 0; 
					  border-top: 10px solid transparent;
					  border-bottom: 10px solid transparent;
					  border-right: 15px solid $color-neutral;
					  position:absolute;
					  left:-5px;
					  top:50%;
					  transform:translateY(-50%);
					}
				}
			}
		}
	}
}

@media(max-width:767px){
	.touchpoints{
		&__image-wrapper{
			padding-top:0;
			.list--touchpoints{
				margin-bottom:0;
				padding-left:30px;
				position:relative;
				&:before{
					content:'';
					position:absolute;
					left:30px;
					top:0;
					width:1px;
					height:100%;
					border-left:2px dashed $color-neutral;
				}
				&:after{
					content:'';
					width: 0; 
				  height: 0; 
				  border-right: 10px solid transparent;
				  border-top: 15px solid $color-neutral;
				  border-left: 10px solid transparent;
				  position:absolute;
				 	left:31px;
				  bottom:0;
				  transform:translate(-50%, 100%);
				}
				li{
					width:100%;
					position:static;
					transform:translate(0, 0);
					padding:0;
					margin-bottom:60px;
					padding-left:50%;
					left:auto;
					right:auto;
					bottom:auto;
					top:auto;
					position:relative;
					&:before{
						content:'';
						height:10px;
						width:10px;
						border-radius:50px;
						background:$color-neutral;
						position:absolute;
						left:45%;
						top:50%;
						transform:translateY(-50%);
					}
					&:after{
						content:'';
						width:45%;
						border-bottom:2px dashed $color-neutral;
						position:absolute;
						left:0;
						top:50%;
						transform:translateY(-50%);
					}
					&:first-of-type{
						&:before{
							top:-4px;
							transform:translateY(0);
						}
						&:after{
							top:0;
							transform:translateY(0);
						}
					}
					.touchpoint__icon{
						margin:0 auto;
						margin-bottom:15px;
						transform:translate(0,0);
					}
					.touchpoints__arrow{
						display:none;
					}
				}
			}
		}
	}
}