.notification{
	position:fixed;
	z-index:99;
	width:100%;
	bottom:0;
	transform:translateY(100%);
	background:$color-neutral-dk;
	color:$color-bg;
	padding:30px 0;
	transition:transform 0.6s ease-in-out;
	max-height:100%;
	overflow-y:auto;
	p{
		@include set-type(18, 400, 28);
		&:last-of-type{
			margin-bottom:0;
		}
	}
	a{
		color:$color-teal-lt;
		@include link-interaction($color-teal-lt, 'true');
		font-weight:400;
	}
	.container{
		padding-right:130px;
		position:relative;
	}
	&--active{
		transform:translateY(0);
	}
	&__dismiss{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		right:15px;
		@extend .button;
		@extend .button--primary;
		margin:0;
		&:active{
			transform:translateY(-50%);
		}
	}
}

@media(max-width:767px){
	.notification{
		.container{
			padding-right:15px;
		}
		&__dismiss{
			position:static;
			margin-top:20px;
			top:0;
			transform:translateY(0);
			&:active{
				transform:translateY(0);
			}
		}
	}
}

.announcement {
	background:$color-yellow;
	color:$color-neutral-dk;
	padding: 19px 0;
	max-height: 100%;
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	transition:padding 0.2s ease-in-out, background 0.2s ease-in-out, top 0.2s ease-in-out;
	
	p {
		@include set-type(22, 600, 36);
		margin-bottom: 0;
		display: flex;
		align-items: center;
	}

	.button--light {
		font-size: 16px;
		margin-left: 1rem;
		margin-top:0;
		padding:7px 25px;
	}

	&__dismiss{
		background: transparent;
		border: 0 none;
		font-size: 1rem;
		display: inline-block;
		cursor:pointer;

		&:hover, &:active, &:focus {
			text-decoration: underline;
		}

		span {
			vertical-align: middle;
		}

		i {
			margin-left: 8px;
			vertical-align: middle;
			font-size: 1.5rem;
		}
	}

	.banner-icon {
		width: 36px;
		height: auto;
		display: inline-block;
		margin-right: 1rem;
		vertical-align: middle;
	}

	@media(max-width:767px){
		.button--light {
			margin-left: 0;
		}

		p {
			@include set-type(18, 600, 26);
			margin-bottom: 0;
			display: flex;
			align-items: center;
		}

		.banner-icon-desktop {
			display: none;
		}

		.grid-desktop {
			margin-top:1rem;
			margin-bottom:1rem;
		}
	}
}

.scrolled{
	.announcement {
		padding:10px 0;
	}
}

.admin-bar{
	.announcement {
		margin-top:32px;
	}
}